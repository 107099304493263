.bubble-wrapper {
    position: fixed;
    z-index: 0;
}

.bubble-float {
    animation: float var(--duration) ease-in-out infinite;
    animation-delay: var(--delay);
}

@keyframes float {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    33% {
        transform: translate(15px, -15px) rotate(2deg);
    }
    66% {
        transform: translate(-10px, 10px) rotate(-2deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}
