
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
@keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  @keyframes glowPulse {
    from {
      filter: drop-shadow(0 4px 8px rgba(255, 255, 255, 0.8));
    }
    to {
      filter: drop-shadow(0 0 20px rgba(32, 83, 121, 0.396));
    }
  }

.loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    overflow: hidden;
  }

  .pulsingCircle {
    position: absolute;
    width: 300px; 
    height: 300px;
    border-radius: 50%;
    background-color: rgba(0, 122, 204, 0.15);
    animation: pulse 3s ease-in-out infinite;
    z-index: 1;
  }

  .rotatingRing {
    position: absolute;
    width: 170px;
    height: 170px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    /* border-top-color: rgba(0, 122, 204, 0.7); */
    border-top-color: rgba(174, 223, 255, 0.7);
    animation: spin 2s linear infinite;
    z-index: 2;
  }

.logoWrapper {
    position: relative;
    z-index: 3;
  }
  
  .logoImage {
    /* filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0)), drop-shadow(0 0 20px rgba(0, 122, 204, 0.5));    */
    filter: drop-shadow(0 4px 8px rgba(255, 255, 255, 0.5));
    animation: float 2s ease-in-out infinite;
    /* glowPulse 2s infinite alternate ease-in-out; */
  }
  
