html, body {
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

*, *:before, *:after {
    box-sizing: inherit;
}

:root {
    direction: rtl;
}

body {
    direction: rtl;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 20px;
    height: 20px;
    z-index: 10;
    transition: all 0.3s ease;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
    background-color: #ADD8E6; /* Light blue color */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #87CEEB; /* Slightly darker on hover */
}